<div class="container">
    <div class="section-title">
        <h2>Produtos e serviços</h2>
        <div class="bar"></div>
        <p>Potencialize os resultados da sua área de Tecnologia da Informação. Podemos ajuda-los no desenvolvimento do
            software para o seu negócio, desenvolvimento mobile (aplicativos), sites, gerenciamento de projetos de TI e
            com mentoria/treinamentos para sua equipe de TI.</p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-code"></i>
                </div>
                <h3>Desevenvolvimento de software - Sob demanda</h3>
                <p>Com método de desenvolvimento de software ágil e nosso know how em TI podemos desenvolver o software
                    que a sua empresa precisa. Asseguramos a nossa excelência na entrega do projeto atendendo as
                    expectativas de escopo, prazo, qualidade e investimentos.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-user-friends"></i>
                </div>
                <h3>Outsourcing</h3>
                <p>Melhore a performance da sua área de TI contratando nosso serviço de outsourcing. Com profissionais
                    qualificados, focados em resultados e comprometidos com seu negócio iremos acelerar a entrega dos
                    projetos do seu negócio.</p>
            </div>
        </div>



        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-project-diagram"></i>
                </div>
                <h3>Inovação de processos</h3>
                <p>Analisamos e sugerindo formas inovadoras para automatizar processos de negócios repetitivos e frágeis
                    que por meio da tecnologia podem ser automatizados. Com profissionais especialista e certificados em
                    Gestão de Processos podemos melhorar a performance de todas as áreas funcionais do seu negócio.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-tasks"></i>
                </div>
                <h3>Gerenciamento de projetos</h3>
                <p>Com foco em metodologias ágeis em Gerenciamento de Projeto, minimizamos os riscos que envolvem o
                    projeto, monitoramos o tempo/escopo e asseguramos ao cliente garantia de qualidade do produto. </p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-book-reader"></i>
                </div>
                <h3>Mentoria e treinamento</h3>
                <p>Evolua o sua equipe interna de desenvolvimento. Com profissionais experientes e habilidades
                    fundamentais para formação e aperfeiçoamento de profissionais de TI, temos treinamento e um processo
                    de mentoria especifico para evoluir sua equipe de desenvolvimento.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-file-word"></i>
                </div>
                <h3>Desenvolvimento de Site</h3>
                <p>Desenvolvemos o site do seu negócio totalmente atual e moderno. Com um método próprio para
                    desenvolvimento de site entregamos o site com designer responsivo, gerenciador de conteúdo e
                    integração com as redes sociais</p>
            </div>
        </div>
    </div>
</div>
