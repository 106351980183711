<div class="container">
    <div class="section-title">
        <h2>Olá, Prazer! Somos a Adapte :)</h2>
        <div class="bar"></div>
        <p>Em um mundo em constante mudança e evolução, nascemos com o propósito de contribuir com a sociedade
            conectando pessoas, tecnologia e negócio através de soluções inovadoras! Ficamos felizes em tê-lo conosco.
        </p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h3>Amamos pessoas e tecnologia</h3>
                <div class="bar"></div>
                <p>Nossa missão como empresa é entregar tecnologia e inovação de excelência com gente que faz o que ama.
                    Ter gente apaixonado por tecnologia é o foco do nosso negócio. Acreditamos que quando amamos aquilo
                    que fazemos, tudo flui melhor e temos entregas com mais qualidade. </p>
                <p>Nossa área de Gente e Gestão seleciona e recruta nossos talentos buscando sempre pessoas com nossa
                    energia e DNA. Não basta ter qualificações técnicas, acreditamos que é de fundamental importância
                    nosso time carregar nas veias nossas paixões em comum.</p>
                <p>Nosso primeiro e mais importante valor são as “PESSOAS”, não podemos imaginar a Adapte sem pessoas! O
                    Foco central da nossa gestão é baseado em Gente! Desenvolvemos pessoas e seres humanos, para em
                    conjunto e trabalhando em equipe, entreguem os melhores resultados e produto para nossos clientes.
                </p>
                <p>Nossos outros valores são “INTEGRIDADE”, “EXCELÊNCIA” E “EVOLUÇÃO”. A composição dessas palavras e
                    comportamentos estabelece o nosso “Jeito de Ser - Adapte” que serve como base para nossa conduta
                    como profissionais e seres humanos!</p>
                <!-- <div class="about-btn">
                    <a routerLink="/" class="default-btn">Download Now <span></span></a>
                </div> -->
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/about.jpg" alt="image">
            </div>
        </div>
    </div>
</div>
