import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent {
    msg: string;
    ctt = "+5585991416468";
    constructor(private toastr: ToastrService) {}

    send() {
        if (!this.msg || this.msg.length <= 1) {
            this.toastr.error("Preencha a mensagem", "Formulário incompleto");
        } else {
            let link = `https://wa.me/${this.ctt}?text=${this.msg.replace(
                " ",
                "%"
            )}`;
            window.open(link, "_blank");
        }
    }
}
