<div class="container">
    <div class="section-title">
        <h2>Fale conosco</h2>
        <div class="bar"></div>
        <p>Entre em contato e agende uma conversa com nossos analistas de negócios.</p>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="contact-form">
                <form id="contactForm" #form (submit)="send()">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" required
                                    placeholder="Seu nome">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" required
                                    placeholder=" Email">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control"
                                    placeholder="Telefone">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                    placeholder="Assunto">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" [(ngModel)]="msg" class="form-control" id="message" cols="30"
                                    rows="6" required placeholder="Mensagem"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="default-btn">Enviar Mensagem
                                    <i class="fab fa-whatsapp"></i></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Entre em contato conosco por telefone ou e-mail</h3>
                    <h2>
                        <a href="tel:+55 85991416468">+55 (85) 99141-6468</a>
                        <span>Ou</span>
                        <a href="mailto:contato@adapteti.com.br">contato@adapteti.com.br</a>
                    </h2>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li> -->
                        <li><a href="https://www.linkedin.com/company/adapte-tecnologia-e-inova%C3%A7%C3%A3o" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.instagram.com/adapteti/" target="_blank"><i
                                    class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
