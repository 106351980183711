<div class="container">
    <div class="section-title">
        <h2>Nossos Heads</h2>
        <div class="bar"></div>
        <p>Apaixonados pelo que fazem, eles se destacam pelo profissionalismo, conhecimento técnico e habilidade para
            criar alinhamento na empresa.</p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/dr.jpeg" alt="image">

                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li> -->
                        <li><a href="https://www.linkedin.com/in/deykson-robert-mba-cbpp-97b7bba2/" target="_blank"><i
                                    class="fab fa-linkedin-in"></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Deykson Robert</h3>
                        <h6>CEO</h6>
                        <p>Responsável pelas áreas comercial,
                            vendas e estratégica.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/ab.jpeg" alt="image">

                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li> -->
                        <li><a href="https://www.linkedin.com/in/anderson-benevides-258893a0/" target="_blank"><i
                                    class="fab fa-linkedin-in"></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Anderson Benevides</h3>
                        <h6>CTO</h6>
                        <p>Diretor técnico responsável pela operação e execução de projetos de tecnologia.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/rl.jpeg" alt="image">

                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li> -->
                        <li><a href="https://www.linkedin.com/in/rodrigo-lucas-gomes-da-nobrega-94811540/"
                                target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Rodrigo Lucas</h3>
                        <h6>CFO</h6>
                        <p>Responsável pelas áreas
                            administrativa, financeira e Gente.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
